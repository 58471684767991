import React from 'react'
import { Layout } from "./../components/Layout";


export default function Books() {

   
  return (
    <Layout>
      
        
        <h1 style={{fontWeight: 700, fontSize: "25px", textAlign: "center", marginTop: "30px"}}>Timnik's Top Book Recommendations</h1>
        <p style={{ fontSize: "18px", textAlign: "center", marginTop: "0px", marginBottom: "30px"}}>(in order)</p>

       
    </Layout>
  )
}
