import {
  Flex,
  Stack,
  Wrap,
  Heading,
  chakra,
  Button,
  Image,
  Text,
  Container,
  Skeleton,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";

import { BiLinkExternal } from "react-icons/bi";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { Layout } from "./../components/Layout";

export default function Consulting() {
  return (
    <Layout>
      <Image
        src="/images/pages/consulting/consulting.png"
        width="100%"
        alt="background"
        objectFit="cover"
        height="400px"
        filter="brightness(.8)"
        borderWidth="10px"
        borderColor="#141414"
      />

      <Text
        color="white"
        fontSize="40px"
        fontWeight="600"
        width="800px"
        marginTop="40px"
        marginBottom="10px"
      >
        Our world is built on communication
      </Text>

      <Text color="white" fontSize="20px" marginTop="0px" display="flex">
        In the realm of communication, shaping perceptions is essential. Whether
        you're a corporation, government agency, or political figure,
        prioritizing public opinion is crucial. We assist you in addressing the
        challenges of communication and public perception by implementing
        effective solutions and strategies. Our approach aims to communicate
        messages clearly and protect individuals from the influences of
        propaganda and psychological operations.{" "}
      </Text>

      <Text
        color="white"
        fontSize="50px"
        fontWeight="700"
        marginTop="50px"
        marginBottom="20px"
      >
        Our Consulting Departaments
      </Text>

      <Flex justifyContent="center" flexWrap="wrap">
        <Box width={{ base: "100%", xl: "33%" }} padding="12px" color="gray">
          <Text
            color="white"
            fontSize="25px"
            fontWeight="600"
            marginTop="0px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Image
              src="/images/icons/pr.png"
              width="150px"
              marginTop="10px"
              marginRight="5px"
            />
            <span style={{ marginTop: "15px" }}>Public Relantions</span>
          </Text>
        </Box>

        <Box width={{ base: "100%", xl: "33%" }} padding="12px" color="gray">
          <Text
            color="white"
            fontSize="25px"
            fontWeight="600"
            marginTop="0px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Image
              src="/images/icons/political.png"
              width="150px"
              marginTop="10px"
              marginRight="5px"
            />
            <span style={{ marginTop: "15px" }}>
              Political Campaign Strategy
            </span>
          </Text>
        </Box>

        <Box width={{ base: "100%", xl: "33%" }} padding="12px" color="gray">
          <Text
            color="white"
            fontSize="25px"
            fontWeight="600"
            marginTop="0px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Image
              src="/images/icons/propaganda.png"
              width="150px"
              marginTop="10px"
              marginRight="5px"
            />
            <span style={{ marginTop: "15px" }}>
              Propaganda & Psychological Operations Analysis
            </span>
          </Text>
        </Box>

        <Text color="white" fontSize="20px" display="flex" marginTop="50px">
          <ul>
            <li>
              <span style={{ fontWeight: "bold" }}>Public Relantions</span> - We
              assist you in managing your public perception, ensuring it remains
              pristine and wonderful.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>
                Political Campaing Strategy
              </span>{" "}
              - We aid in developing winning campaigns, shaping persuasive
              messages, and effectively engaging your audience in the political
              arena.
            </li>
            <li>
              <span style={{ fontWeight: "bold" }}>
                Propaganda & Psychological Operations Analysis
              </span>{" "}
              - We safeguard you and your public from opposing propaganda and
              psychological operations, ensuring a secure and informed
              environment.
            </li>
          </ul>
        </Text>
      </Flex>
    </Layout>
  );
}
