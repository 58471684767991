import {
  Flex,
  Stack,
  Wrap,
  Heading,
  chakra,
  Button,
  Image,
  Text,
  Container,
  Skeleton,
  useColorModeValue,
  Box,
} from "@chakra-ui/react";

import { BiLinkExternal } from "react-icons/bi";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { Layout } from "./../components/Layout";

export default function Home() {
  return (
    <Layout>
      <Image
        src="/images/background.jpg"
        width="100%"
        alt="background"
        objectFit="cover"
        height="400px"
        filter="brightness(.8)"
      />
      <p
        style={{
          fontStyle: "italic",
          textAlign: "right",
          color: "gray",
          fontSize: "10px",
        }}
      >
        “Aeneas Flees Burning Troy,” by Federico Barocci (1598). Via Wikimedia
        Commons.
      </p>

      <Text
        color="white"
        fontSize="30px"
        fontWeight="600"
        width={{ base: "100%", md: "800px" }}
        marginTop="40px"
      >
        Studying the human behavior and empowering lives through innovative
        psychological technologies.
      </Text>

      <Text color="white" marginTop="40px" fontSize="20px" color="gray">
        Advanced Psychological Technologies Research (APTR) is a research and
        deployment company. Our mission is the in-depth exploration of human
        behaviour with technology and development of innovative solutions to
        empower people in navigating the complexities of the modern
        technological life.
        <br /> <br />
        Central to APTR's mission is the continuous pursuit of knowledge. Our
        research initiatives delve into diverse aspects of human behavior,
        technology interaction, and the psychological impact of emerging
        technologies. We then use this knowledge to create software and
        different technologies to help humans.
        <br /> <br />
        We also help other organizations through our three consulting
        departaments: Public Relantions, Political Campaign Strategy and
        Propaganda & Psychological Operations Analysis.
      </Text>

      {/* <Link to="/about">
        <Button marginTop="10px">Learn about APTR</Button>
      </Link> */}

      <Text
        color="white"
        fontSize="30px"
        fontWeight="700"
        marginTop="50px"
        marginBottom="20px"
      >
        Software we are working on
      </Text>

      <Flex justifyContent="center" flexWrap="wrap">
        <Box width={{ base: "100%", lg: "50%" }} padding="12px" color="gray">
          <Text
            color="white"
            fontSize="40px"
            fontWeight="600"
            marginTop="0px"
            textAlign="center"
            display="flex"
          >
            <Image
              src="/images/icons/wellbe.png"
              height="40px"
              marginTop="10px"
              marginRight="5px"
            />
            WellBe
          </Text>

          <Text>
            Your personal sanctuary for mental well-being. In the hustle and
            bustle of daily life, finding moments of peace and tranquility is
            essential for a healthy mind. WellBe is your companion on the
            journey to better mental health, offering a diverse range of
            mindfulness exercises and meditation practices tailored to your
            needs.
          </Text>
        </Box>

        <Box width={{ base: "100%", lg: "50%" }} padding="12px" color="gray">
          <Text
            color="white"
            fontSize="40px"
            fontWeight="600"
            marginTop="0px"
            textAlign="center"
            display="flex"
            NeuralAds
          >
            TrustShield
          </Text>

          <Text>
            Your vigilant guardian against scams, spam, and propaganda.
            Harnessing the power of cutting-edge AI technology, TrustShield is
            your comprehensive solution for a secure digital experience. We
            understand the importance of a secure online experience, and
            TrustShield is your ultimate defense against scams, spam, and
            propaganda.
          </Text>
        </Box>
      </Flex>
    </Layout>
  );
}
